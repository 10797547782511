import { navigate } from "gatsby";
import React from "react";
import { HiOutlineBookOpen } from "react-icons/hi";
import { IoBodyOutline } from "react-icons/io5";
import { RiMentalHealthLine } from "react-icons/ri";
import { TbStretching } from "react-icons/tb";
import { useLocalStorage } from "usehooks-ts";
import courseImg from "../images/course-modal.jpg";
import { Button } from "./button";
import { Modal } from "./modal";

const checkIsLessThan3DaysFromNow = (date) => {
  const parsedDate = new Date(date);
  if (isNaN(parsedDate.getTime())) {
    return false;
  }
  return (
    parsedDate.getTime() >
    new Date(Date.now() - 3 * 24 * 60 * 60 * 1000).getTime()
  );
};

export const CourseModal = () => {
  const [lastSeenAt, setLastSeenAt] = useLocalStorage(
    "course-modal-last-seen-at",
    null
  );
  const [hideModal, setHideModal] = useLocalStorage("hide-course-modal", false);
  const [checked, setChecked] = React.useState(false);

  const handleClose = () => {
    setHideModal(checked);
    setLastSeenAt(new Date().toISOString());
  };

  const handleNavigate = () => {
    setHideModal(checked);
    setLastSeenAt(new Date().toISOString());
    navigate("/platform");
  };

  if (
    hideModal ||
    (lastSeenAt && checkIsLessThan3DaysFromNow(lastSeenAt)) ||
    typeof window === "undefined"
  ) {
    return null;
  }

  return (
    <Modal onExit={handleClose}>
      <div className="size-full flex items-center justify-center">
        <div className="grid grid-cols-1 lg:grid-cols-2 w-[320px] lg:w-[800px] max-h-[650px] bg-white rounded-lg overflow-hidden">
          <div className="h-[280px] lg:h-full">
            <img
              src={courseImg}
              alt="Kurs"
              className="size-full object-center object-cover"
            />
          </div>

          <div className="px-5 py-5 lg:py-8">
            <h1 className="font-bold text-2xl">Autoterapia online</h1>
            <p className="mt-1 text-slate-600 text-sm">
              Osiągnij niezależność zdrowotną bez leków, korzystając z platformy
              ze sprawdzoną wiedzą stworzoną przez doświadczonych terapeutów.
            </p>

            <p className="mt-6 text-slate-800 font-medium underline hidden lg:block">
              Platforma dedykowana jest dla każdego kto:
            </p>

            <div className="mt-3 lg:flex flex-col gap-6 hidden">
              <div className="flex items-center gap-4 max-w-[500px]">
                <div className="rounded-full p-2 bg-gray-200">
                  <IoBodyOutline size="32" className="text-tertiary" />
                </div>
                <span className="text-sm text-slate-800">
                  Chce zadbać o swoje zdrowie
                </span>
              </div>

              <div className="flex items-center gap-4 max-w-[500px]">
                <div className="rounded-full p-2 bg-gray-200">
                  <HiOutlineBookOpen size="32" className="text-tertiary" />
                </div>
                <span className="text-sm text-slate-800">
                  Dowiedzieć się, co i kiedy robić, tak aby skutecznie
                  zmniejszyć dolegliwości
                </span>
              </div>

              <div className="flex items-center gap-4 max-w-[500px]">
                <div className="rounded-full p-2 bg-gray-200">
                  <TbStretching size="32" className="text-tertiary" />
                </div>
                <span className="text-sm text-slate-800">
                  Nauczyć prawidłowego rolowania, rozciągania czy automasażu.
                </span>
              </div>

              <div className="flex items-center gap-4 max-w-[500px]">
                <div className="rounded-full p-2 bg-gray-200">
                  <RiMentalHealthLine size="32" className="text-tertiary" />
                </div>
                <span className="text-slate-800 text-sm">
                  Poznać w jaki sposób stres emocjonalny wpływa na napięcie w
                  naszym ciele.
                </span>
              </div>
            </div>

            <div className="mt-6 lg:mt-12 flex flex-col gap-2">
              <div className="flex justify-between items-center gap-4">
                <Button
                  color="black"
                  small
                  onClick={handleClose}
                  className="flex-1"
                >
                  Zamknij
                </Button>
                <Button
                  small
                  onClick={handleNavigate}
                  className="flex-1 whitespace-nowrap"
                >
                  Dowiedz się więcej
                </Button>
              </div>

              <label className="text-slate-600 text-sm">
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                Nie pokazuj tego ponownie
              </label>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
