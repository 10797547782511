import * as React from "react";
import cn from "classnames";

export const Button = ({ color = "green", className, small, ...rest }) => {
  return (
    <button
      className={cn(
        "inline-block uppercase rounded-button text-white text-sm md:text-base text-center hover:shadow-lg transition-shadow duration-300",
        {
          "bg-tertiary": color === "green",
          "bg-black": color === "black",
          "p-2 md:px-7 py-3": !small,
          "p-2 md:px-4 py-3": small,
        },
        className
      )}
      {...rest}
    />
  );
};

export const ButtonOutline = ({ small, ...rest }) => {
  return (
    <button
      className={cn(
        "inline-block uppercase rounded-button text-sm md:text-base text-center whitespace-nowrap hover:shadow-lg transition-shadow duration-300",
        {
          "bg-transparent border border-black text-black": true,
          "p-2 md:px-7 py-3": !small,
          "p-2 md:px-4 py-3": small,
        }
      )}
      {...rest}
    />
  );
};

export const SecondaryButton = ({ className, color = "base", ...rest }) => {
  return (
    <button
      className={cn(
        "inline-block text-base font-bold uppercase border-b cursor-pointer pb-1",
        {
          "text-linkGray border-linkGray": color === "base",
          "text-tertiary border-tertiary": color === "green",
        }
      )}
      {...rest}
    />
  );
};

export const CardButton = ({ color = "green", ...props }) => {
  return (
    <button
      className={cn(
        "inline-block border-transparent border font-medium px-4 py-2 text-sm rounded shadow-sm transition-shadow",
        {
          "bg-tertiary text-white": color === "green",
          "bg-black text-white": color === "black",
          "bg-transparent border-secondary hover:bg-": color === "transparent",
        }
      )}
      {...props}
    />
  );
};
