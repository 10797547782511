import React, { useEffect } from "react";
import Icon from "./icons";

export const Modal = ({ onExit, children, withCloseIcon = true }) => {
  useEffect(() => {
    const handler = (evt) => {
      if (evt.key === "Escape") {
        onExit();
      }
    };
    window.addEventListener("keydown", handler);
    return () => {
      window.removeEventListener("keydown", handler);
    };
  }, [onExit]);

  return (
    <div className="fixed inset-0 w-screen h-screen z-20 bg-black bg-opacity-90">
      {withCloseIcon && (
        <div className="absolute top-6 md:top-12 right-6 md:right-12 cursor-pointer text-white">
          <Icon name="Close" className="w-8 h-8" onClick={() => onExit()} />
        </div>
      )}
      {children}
    </div>
  );
};
