import React from "react";
import CookieConsent from "react-cookie-consent";
import "@fontsource/merriweather";
import "@fontsource/kaushan-script";
import "@fontsource/noto-sans";
import { Swiper } from "swiper";
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import { CourseModal } from "./src/components/course-modal";

import "swiper/css";
import "./src/css/index.css";

Swiper.use([Navigation, Autoplay, Pagination]);

export const wrapPageElement = ({ element }) => {
  return (
    <>
      {element}
      <CookieConsent
        buttonClasses="btn bg-black p-2 md:px-4 py-3 my-2 md:mx-4"
        containerClasses="p-6 bg-bgSecondary text-textBase flex md:items-center md:justify-center flex-wrap fixed left-0 bottom-0 w-full z-50 shadow-inner"
        disableStyles
        buttonText="Zamknij"
      >
        Strona korzysta z ciasteczek. Dalsze korzystanie ze strony oznacza, że
        zgadzasz się na ich użycie
      </CookieConsent>
      <CourseModal />
    </>
  );
};
